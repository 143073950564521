<template>
  <div>

    <t-loader
      v-if="showLoader"
      :loadingMessageList="[
        'Estamos cotizando tus seguros,',
        'espera un momento.'
      ]"
    />

    <div id="DataForServicesPage_body">
      <div class="header_icons">
        <div
          v-for="(item, index) in selectedProductCategories"
          :key="item.id"
          class="list-complete-item relative"
          data-aos="zoom-in"
          data-aos-duration="500"
          :data-aos-delay="500 + (index - 1) * 300"
        >
          <img
            :src="item.image_xs_primary"
          />
        </div>
      </div>

      <div >

        <div class="pet_lottie_container">
          <div class="lottie_container">
            <lottie class="lottie"
              v-show="isWriting"
              :options="writingAnimation.defaultOptions"
              @animCreated="handleAnimationW"
              @data_ready="data_ready_w"
              :key="'check_w'"
            />
  
            <lottie class="lottie" 
              v-show="!isWriting"
              :options="listeningAnimation.defaultOptions"
              @animCreated="handleAnimationL"
              @data_ready="data_ready_l"
              :key="'check_l'"
            />
          </div>
        </div>




      </div>

      <evaluation
        :questions="requerimentList"
        :responses="responses"
        @change-responses="updateResponses"
      ></evaluation>
    </div>

  </div>
</template>


<script>
  import Lottie from "@/components/lottie.vue";
  import writingData from "@/assets/animations/writing.json";
  import listeningData from "@/assets/animations/writing.json";

  import Evaluation from "../components/Evaluation/Evaluation.vue";
  import { mapMutations, mapActions, mapGetters } from "vuex";

  export default {
    name: "DataForServicesPage",
    data: function() {
      return {
        showAPIErrors: false,
        ApiErrorMessages: [],
        showLoader: false,
        requerimentList: [],
        responses: [],
        productIds: [],
        disponibleTypeQuestions: {
          date: {
            form: "date",
            responseType: "date",
            availableResponses: false /* Indica si se debe enviar como parámetro un listado de respuestas disponibles, en este caso, no se requiere */
          },
          input: {
            form: "input",
            responseType: "text" /* text/email/number/password */,
            availableResponses: false /* Indica si se debe enviar como parámetro un listado de respuestas disponibles, en este caso, no se requiere */
          },
          option: {
            form: "option",
            responseType:
              "number" /* ID de la opcion seleccionada por el usuario */,
            availableResponses: true /* Indica si se debe enviar como parámetro un listado de respuestas disponibles, en este caso, sí de deben enviar las opciones */
          }
        },
        writingAnimation: {
          totalFrames: 93,
          defaultOptions: {
            autoplay: true,
            loop: true,
            animationData: writingData,
          },
          animationSpeed: 1,
        },
        listeningAnimation: {
          totalFrames: 93,
          defaultOptions: {
            autoplay: true,
            loop: true,
            animationData: listeningData,
          },
          animationSpeed: 1,
        },
        isWriting: false,
      };
    },
    components: {
      Evaluation,
      Lottie,
    },
    computed: {
      ...mapGetters(["shoppingCar"]),
      selectedProductCategories() {
        return this.$store.state.BuyProcessStore.productCategories.userSelection;
      }
    },
    mounted() {
      if (
        this.selectedProductCategories == null ||
        this.selectedProductCategories.length == 0
      ) {
        this.$router.push("/");
      } else {
        //console.log("this.selectedProductCategories", JSON.stringify(this.selectedProductCategories));
        this.setInitialState();
      }
    },
    destroyed() {},
    methods: {
      ...mapMutations(["updateFilteredProducts", "updateRequiredDataFromUser"]),
      ...mapActions(["calculateProductsPackages"]),
      resetVariables() {},
      async setInitialState() {
        //console.log("setInitialState selProdCats", JSON.stringify(this.selectedProductCategories));
        let self = this;
        self.resetVariables();
        // Get requirements for products in selected categories
        let categoryIds = self.selectedProductCategories.map(item => item.id);
        try {
          let request_params = {
            filters: {
              for_parent_ids: categoryIds.join(",")
            }
          };
          let products_response = await self.$store.dispatch(
            "getProducts",
            request_params
          );
          //console.log("products_response", JSON.stringify(products_response));

          //Devuelve únicamente los ids principales del arreglo
          self.productIds = products_response
            .map(function(x) {
              return x.id;
            })
            .join(",");
          //console.log("self.productIds: ", JSON.stringify(self.productIds));

          //Obtiene lista de requerimientos del seguro seleccionado
          request_params.filters.for_parent_ids = self.productIds;
          let requeriments_response = await self.$store.dispatch(
            "getProductsRequirements",
            request_params
          );

          let resultWitoudDuplicates = self.getWithoutDuplicates(
            requeriments_response,
            "id"
          );

          self.requerimentList = resultWitoudDuplicates.sort(
            (a, b) => a.sort_value - b.sort_value
          );
        } catch (error) {
          alert("No fue posible obtener los productos");
        }
        //console.log("self.requerimentList: " + JSON.stringify(self.requerimentList));
        self.$store.commit("updateBgApp", "");
      },
      updateResponses(data) {
        this.responses = data.responses;
        this.updateRequiredDataFromUser(data.responses);
        if (data.isEnd) {
          // Esto indica que se ha concluido la evaluación, debemos calcular a partir de las respuestas, los paquetes que se mostrarán al usuario
          this.showLoader = true;
          let category_ids = this.selectedProductCategories.map(item => item.id);
          let questionAnswers = data.responses.map(item => item.value);
          this.getCalculatedProducts(category_ids, questionAnswers);
        }
      },
      async getCalculatedProducts(category_ids, responsesData) {
        /* Esta debe ser una API a la que se le envían las respuestas a las preguntas y devuelve el listado de paquetes que se mostrarán al usuario */
        let self = this;

        let payload = {
          data: {
            requeriments: responsesData,
            categories: category_ids
          }
        };
        self
          .calculateProductsPackages(payload)
          .then(result => {

            if(result.messages.errors.length == 0){

              let payload = {
                data: result.data
              }

              if (result.data.length > 0) {
                self.updateFilteredProducts(payload);
              } else {
                // console.log("NO se encontraron productos");
              }

              //Envia las respuestas de las preguntas
              self.shoppingCar.setQuoteData(result.extra_data.quote_data);

              result.messages.errors.forEach(error => {

                self.$swal({
                  icon: "error",
                  title: error.title,
                  text: error.code + ": " + error.description,
                  showConfirmButton:false,
                  timer: 10000,
                });
                
              })

              self.next(result.data)

            } else {

              // console.log(result.messages)
              var { description } = result.messages.errors[0]

              this.$swal({
                footer: "Lo sentimos no logró ser procesada tu cotización, vuelve a cotizar con valores diferentes",
                text: description,
                showConfirmButton: false,
                timer:8000
              })

              this.$router.push("/")

            }

          })
          .catch(function(error) {
            //Quita el loader ...
            // console.log("error.response: ",error.response)
            self.$emit("processing", false);
            if (error.response) {
              self.showAPIErrors = true;
              self.ApiErrorMessages = error.response.data.messages.errors;
            }
          })
          .finally(() => {
            self.showLoader = true;
          });
      },
      next(data) {
        this.$router.push({
          name: "filtered-products",
          params: { packages: data }
        });
      },
      getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
      },
      getWithoutDuplicates(array, key) {
        return [...new Map(array.map(item => [item[key], item])).values()];
      }
    },
    watch: {
      selectedProductCategories(newValue) {
        if (!newValue || newValue.length == 0) {
          this.$router.push("/");
        }
      }
    }
  };

</script>