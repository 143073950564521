var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-address"},[_c('div',{staticClass:"form-header"},[_c('div',{staticClass:"description"},[_vm._v("\n      "+_vm._s(_vm.formDetail.description)+"\n    ")]),_vm._v(" "),(_vm.formDetail.help_info)?_c('div',{staticClass:"help-info"},[_vm._v("\n      "+_vm._s(_vm.formDetail.help_info)+"\n    ")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"form-body"},[_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.value.value[0]),expression:"value.value[0]",modifiers:{"trim":true}}],ref:"focusForm",staticClass:"\n          w-full\n          text-secondary\n          px-5\n          text-2xl\n          md:text-3xl\n          border border-primary border-r-0 border-l-0 border-t-0\n        ",class:[
          !this.$v.value.value.$each[0].required && _vm.showErrors
            ? 'invalid'
            : '' ],attrs:{"type":_vm.validInputTypes.includes(_vm.formDetail.form_subtype)
            ? _vm.formDetail.form_subtype
            : 'text',"placeholder":"Calle"},domProps:{"value":(_vm.value.value[0])},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.value.value, 0, $event.target.value.trim())},function($event){_vm.$emit('input', {
            id: _vm.formDetail.id,
            value: [$event.target.value, _vm.value.value[1]],
          });
          _vm.setWritingTime(500);}],"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.gotoNext()},"blur":function($event){return _vm.$forceUpdate()}}}),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.value.value[1]),expression:"value.value[1]",modifiers:{"trim":true}}],staticClass:"\n          w-full\n          sm:w-1/3\n          mb-2 mb-sm-0\n          text-secondary\n          px-5\n          text-2xl\n          md:text-3xl\n          border border-primary border-r-0 border-l-0 border-t-0\n        ",class:[
          !this.$v.value.value.$each[1].required && _vm.showErrors
            ? 'invalid'
            : '' ],attrs:{"type":_vm.validInputTypes.includes(_vm.formDetail.form_subtype)
            ? _vm.formDetail.form_subtype
            : 'text',"placeholder":"Ext. Num."},domProps:{"value":(_vm.value.value[1])},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.value.value, 1, $event.target.value.trim())},function($event){_vm.$emit('input', {
            id: _vm.formDetail.id,
            value: [_vm.value.value[0], $event.target.value],
          });
          _vm.setWritingTime(500);}],"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.gotoNext()},"blur":function($event){return _vm.$forceUpdate()}}})]),_vm._v(" "),_c('transition',{attrs:{"name":"fade-pop"}},[(_vm.showErrors == true)?_c('div',{staticClass:"w-full flex flex-wrap justify-end items-center"},_vm._l((_vm.errors),function(error,index){return _c('div',{key:'err-' + index,staticClass:"\n            w-full\n            bg-red-100\n            text-red-500\n            border-2\n            rounded-md\n            border-red-400\n            px-10\n            py-1\n            mb-5\n            mt-2\n          "},[_vm._v("\n          "+_vm._s(error)+"\n        ")])}),0):_vm._e()])],1),_vm._v(" "),_c('div',{staticClass:"form-footer"},[_c('button',{staticClass:"px-5 py-2 border-2 border-primary rounded-md text-primary hover:bg-primary hover:text-white transition duration-600 pop",on:{"click":function($event){return _vm.gotoPrevious()}}},[_vm._v("\n      Anterior\n    ")]),_vm._v(" "),_c('button',{staticClass:"px-5 py-2 border-2 border-primary rounded-md text-primary hover:bg-primary hover:text-white transition duration-600 pop",on:{"click":function($event){return _vm.gotoNext()}}},[_vm._v("\n      Siguientes\n    ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }