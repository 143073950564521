<template>

  <div id="form-full-name">
   
    <div class="form-header">

      <div class="description">
        {{ formDetail.description }}
      </div>
      <div
        class="help-info"
        v-if="formDetail.help_info"
      >
        {{ formDetail.help_info }}
      </div>

    </div>

    <div class="form-body">

      <div class="w-full flex flex-wrap sm:flex-nowrap justify-between">

        <input
          ref="focusForm"
          v-model.trim="value.value[0]"
          @input="
            $emit('input', {
              id: formDetail.id,
              value: [$event.target.value, value.value[1], value.value[2]],
            });
            setWritingTime(500);
          "
          :type="
            validInputTypes.includes(formDetail.form_subtype)
              ? formDetail.form_subtype
              : 'text'
          "
          class="
            w-full
            sm:w-1/3
            mb-sm-0
            text-secondary
            px-5
            text-2xl
            md:text-3xl
            border border-primary border-r-0 border-l-0 border-t-0
            mx-1
          "
          :class="[
            !this.$v.value.value.$each[0].required && showErrors
              ? 'invalid'
              : '',
          ]"
          placeholder="Nombre(s)"
          v-on:keyup.enter="gotoNext()"
        />
        
        <input
          v-model.trim="value.value[1]"
          @input="
            $emit('input', {
              id: formDetail.id,
              value: [value.value[0], $event.target.value, value.value[2]],
            });
            setWritingTime(500);
          "
          :type="
            validInputTypes.includes(formDetail.form_subtype)
              ? formDetail.form_subtype
              : 'text'
          "
          class="
            w-full
            sm:w-1/3
            mb-sm-0
            text-secondary
            px-5
            text-2xl
            md:text-3xl
            border border-primary border-r-0 border-l-0 border-t-0
            mx-1
          "
          :class="[
            !this.$v.value.value.$each[1].required && showErrors
              ? 'invalid'
              : '',
          ]"
          placeholder="A. Paterno"
          v-on:keyup.enter="gotoNext()"
        />
        <input
          v-model.trim="value.value[2]"
          @input="
            $emit('input', {
              id: formDetail.id,
              value: [value.value[0], value.value[1], $event.target.value],
            });
            setWritingTime(500);
          "
          :type="
            validInputTypes.includes(formDetail.form_subtype)
              ? formDetail.form_subtype
              : 'text'
          "
          class="
            w-full
            sm:w-1/3
            mb-sm-0
            text-secondary
            px-5
            text-2xl
            md:text-3xl
            border border-primary border-r-0 border-l-0 border-t-0
            mx-1
          "
          :class="[
            !this.$v.value.value.$each[2].required && showErrors
              ? 'invalid'
              : '',
          ]"
          placeholder="A. Materno"
          v-on:keyup.enter="gotoNext()"
        />
      </div>

      <transition name="fade-pop">
        <div v-if="!canContinue" class="w-full flex justify-end items-center">
          <div class="
              w-full
              bg-red-100
              text-red-500
              border-2
              rounded-md
              border-red-400
              px-10
              py-1
              mb-5
              mt-2
            ">
            {{ error_message }}
          </div>
        </div>
      </transition>

      <transition name="fade-pop">
        <div
          v-if="showErrors == true"
          class="w-full flex flex-wrap justify-end items-center"
        >
          <div
            class="
              w-full
              bg-red-100
              text-red-500
              border-2
              rounded-md
              border-red-400
              px-10
              py-1
              mb-5
              mt-2
            "
            v-for="(error, index) in errors"
            :key="'err-' + index"
          >
            {{ error }}
          </div>
        </div>
      </transition>

    </div>

    <div class="form-footer">
      <button
        class="px-5 py-2 border-2 border-primary rounded-md text-primary hover:bg-primary hover:text-white transition duration-600 pop"
        @click="gotoPrevious()"
      >
        Anterior
      </button>
      <button
        class="px-5 py-2 border-2 border-primary rounded-md text-primary hover:bg-primary hover:text-white transition duration-600 pop"
        @click="gotoNext()"
      >
        Siguiente
      </button>
    </div>
    
  </div>

</template>

<script>

  import { mapGetters } from "vuex";
  import { required, minLength, maxLength } from "vuelidate/lib/validators";
  import { validateName } from "../../constants/config";

  export default {
    name: "QuestionInput",
    data: function () {
      return {
        internValue: null,
        showErrors: false,
        canContinue: true,
        error_message: "",
        validInputTypes: [
          "email",
          "password",
          "date",
          "text",
          "number",
          "tel",
          "",
        ],
        timeout: null,
      };
    },
    validations() {
      let validations = {
        value: {
          value: {
            required,
            minLength: minLength(3),
            $each: {
              required,
            },
          },
        },
      };

      if (!this.formDetail.is_mandatory) {
        validations.value.value["$each"] = required;
      }

      if (this.formDetail.in_range.length > 0) {
        // If has range values
        // The validations depends of form type and its required values
        if (this.formDetail.value_type == "str") {
          if (["text", "N/A", "email"].includes(this.formDetail.form_subtype)) {
            // Validations if min and max characters
            if (this.formDetail.in_range.length > 0)
              validations.value.value["$each"]["minLength"] = minLength(
                this.formDetail.in_range[0]
              );
            if (this.formDetail.in_range.length > 1)
              validations.value.value["$each"]["maxLength"] = maxLength(
                this.formDetail.in_range[1]
              );
          }
        }
      }
      return validations;
    },
    components: {
    },
    props: {
      formDetail: {
        type: Object,
        required: true,
      },
      required: {
        type: Boolean,
        default: false,
      },
      value: {},
    },
    computed: {
      ...mapGetters(["userData"]),
      errors() {
        let errors = [];
        if (this.showErrors) {
          if (
            "required" in this.$v.value.value.$each[0] &&
            !this.$v.value.value.$each[0].required
          ) {
            errors.push("El nombre es necesario.");
          }
          if (
            "required" in this.$v.value.value.$each[1] &&
            !this.$v.value.value.$each[1].required
          ) {
            errors.push("El A. Paterno es necesario.");
          }
          if (
            "required" in this.$v.value.value.$each[0] &&
            !this.$v.value.value.$each[2].required
          ) {
            errors.push("El A. Materno es necesario.");
          }
        }
        return errors;
      },
    },
    mounted() {
      let self = this;
      if (
        !self.value ||
        (self.value &&
          self.value.value &&
          !self.value.value[0] &&
          !self.value.value[1] &&
          !self.value.value[2])
      ) {
        try {
          self.$emit("input", {
            id: self.formDetail.id,
            value: [
              this.userData.name,
              this.userData.first_name,
              this.userData.last_name,
            ],
          });
        } catch (error) {
          self.$emit("input", {
            id: self.formDetail.id,
            value: [null, null, null],
          });
        }
      }
      self.$nextTick(function () {
        self.$refs["focusForm"].focus();
      });
    },
    methods: {
      setWritingTime(time) {
        let self = this;
        self.isWriting = true;
        clearTimeout(self.timeout);
        self.timeout = setTimeout(() => {
          self.isWriting = false;
        }, time);
      },
      data_ready_w() {
        this.writingAnimation.totalFrames = this.animWriting.totalFrames;
      },
      handleAnimationW: function (anim) {
        let self = this;
        self.animWriting = anim;
        self.animWriting.addEventListener("data_ready", function () {
          self.writingAnimation.totalFrames = self.animWriting.totalFrames;
        });
      },
      data_ready_l() {
        this.listeningAnimation.totalFrames = this.animListening.totalFrames;
      },
      handleAnimationL: function (anim) {
        let self = this;
        self.animListening = anim;
        self.animListening.addEventListener("data_ready", function () {
          self.listeningAnimation.totalFrames = self.animListening.totalFrames;
        });
      },
      gotoPrevious() {
        this.$emit("previous");
      },
      gotoNext() {
        let self = this;
        if (self.required) {
          if( validateName(self.value.value[0]) && validateName(self.value.value[1]) && validateName(self.value.value[2] )) {
            
            // console.log(self.value)
            // console.log(self.value.value)
            // console.log(self.value.value[0])

            var name = this.value.value[0].toLowerCase()
            var first_name = this.value.value[1].toLowerCase()
            var last_name = this.value.value[2].toLowerCase()

            this.value.value[0] = name[0].toUpperCase() + name.substring(1)
            this.value.value[1] = first_name[0].toUpperCase()+ first_name.substring(1)
            this.value.value[2] = last_name[0].toUpperCase()+ last_name.substring(1)

            // console.log(self.value.value)

            if (self.isValid()) {
              if (self.value) {
                self.$emit("next");
              } else {
                self.canContinue = false;
              }
            }
          } else {
            self.error_message = "El nombre unicamente admite letras y espacios."
            self.canContinue = false;
          }
        } else {
          self.$emit("next");
        }
      },
      isValid() {
        let is_valid = false;
        if (this.$v.value.value.$invalid) {
          this.showErrors = true;
        } else {
          is_valid = true;
          this.showErrors = false;
        }
        return is_valid;
      },
    },
  };
</script>

<style>

  input {
    outline: none;
  }

</style>
