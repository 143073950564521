<template>

  <div
    class="form-chubb-valor"
  >

    <div class="form-header">

      <div class="description">
        {{ formDetail.description }}
      </div>
      <div
        class="help-info"
        v-if="formDetail.help_info"
      >
        {{ formDetail.help_info }}
      </div>

    </div>
    

    <div class="form-body">

      <input
        ref="focusForm"
        v-model.trim="value.value"
        @input="
          $emit('input', {
            id: formDetail.id,
            value: getEmitedValue($event.target.value),
          });
          setWritingTime(500);
        "
        type="text"
        class="
          w-full
          text-secondary
          px-5
          text-2xl
          md:text-3xl
          border border-primary border-r-0 border-l-0 border-t-0
        "
        :class="[errors.length && showErrors ? 'invalid' : '']"
        :placeholder="formDetail.placeholder"
        v-on:keyup.enter="gotoNext()"
      />

      <transition name="fade-pop">
        <div v-if="showErrors == true">
          <div
            class="
              w-full
              bg-red-100
              text-red-500
              border-2
              rounded-md
              border-red-400
              px-10
              py-1
              mt-2
            "
            v-for="(error, index) in errors"
            :key="'err-' + index"
          >
            {{ error }}
          </div>
        </div>
      </transition>

    </div>


    <div class="form-footer">
      <button
        class="px-5 py-2 border-2 border-primary rounded-md text-primary hover:bg-primary hover:text-white transition duration-600 pop"
        @click="gotoPrevious()"
      >
        Anterior
      </button>
      <button
        class="px-5 py-2 border-2 border-primary rounded-md text-primary hover:bg-primary hover:text-white transition duration-600 pop"
        @click="gotoNext()"
      >
        Siguientes
      </button>
    </div>
    
  </div>

</template>

<script>
import { required, minValue, maxValue } from "vuelidate/lib/validators";

export default {
  name: "QuestionInput",
  data: function () {
    return {
      internValue: null,
      showErrors: false,
      canContinue: true,
      validInputTypes: [
        "email",
        "password",
        "date",
        "text",
        "number",
        "tel",
        "",
      ],
      timeout: null,
    };
  },
  validations() {
    let validations = {
      internValue: {},
    };

    if (!this.formDetail.is_mandatory) {
      validations.internValue["required"] = required;
    }

    let dependencyQA = this.allResponses.filter(
      (response) => response.id == 30
    )[0];

    if (this.formDetail.in_range.length > 0)
      if (dependencyQA.value == 1) {
        validations.internValue["minValue"] = minValue(
          this.formDetail.in_range[0]
        );
      } else {
        validations.internValue["minValue"] = minValue(0);
      }
    if (this.formDetail.in_range.length > 1)
      validations.internValue["maxValue"] = maxValue(
        this.formDetail.in_range[1]
      );
    return validations;
  },
  components: {

  },
  props: {
    formDetail: {
      type: Object,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    value: {},
    allResponses: {},
  },
  computed: {
    errors() {
      let errors = [];
      if (this.showErrors) {
        if (
          "required" in this.$v.internValue &&
          !this.$v.internValue.required
        ) {
          errors.push("Este valor es necesario, por favor ingresa uno valido.");
        }

        if (
          "minValue" in this.$v.internValue &&
          !this.$v.internValue.minValue
        ) {
          errors.push(
            `La cantidad mínima es $${Number(
              this.$v.internValue.$params.minValue.min
            ).toLocaleString("en-US")}.`
          );
        }
        if (
          "maxValue" in this.$v.internValue &&
          !this.$v.internValue.maxValue
        ) {
          errors.push(
            `La cantidad máxima es $${Number(
              this.$v.internValue.$params.maxValue.max
            ).toLocaleString("en-US")}.`
          );
        }
      }
      return errors;
    },
  },
  mounted() {
    let self = this;
    if (!self.value) {
      self.internValue = 0;
      self.$emit("input", {
        id: self.formDetail.id,
        value: self.getEmitedValue(0),
      });
    } else {
      self.internValue = self.value.value;
      self.$emit("input", {
        id: self.formDetail.id,
        value: self.getEmitedValue(self.value.value),
      });
    }
    self.$nextTick(function () {
      self.$refs["focusForm"].focus();
    });
  },
  methods: {
    getEmitedValue(value) {
      value = String(value).replace(/[^0-9.]?/g, "");
      if (value.includes(".")) {
        value = value.split(".")[0] + "." + value.split(".")[1];
        this.internValue = Number(value);
        value = Number(value).toLocaleString("en-US");
        if (!value.split(".")[1]) {
          // Si no hay valor después del punto, se añade solo el punto
          value += ".";
        }
      } else {
        this.internValue = Number(value);
        value = Number(value).toLocaleString("en-US");
      }
      return "$" + value;
    },
    setWritingTime(time) {
      let self = this;
      self.isWriting = true;
      clearTimeout(self.timeout);
      self.timeout = setTimeout(() => {
        self.isWriting = false;
      }, time);
    },
    data_ready_w() {
      this.writingAnimation.totalFrames = this.animWriting.totalFrames;
    },
    handleAnimationW: function (anim) {
      let self = this;
      self.animWriting = anim;
      self.animWriting.addEventListener("data_ready", function () {
        self.writingAnimation.totalFrames = self.animWriting.totalFrames;
      });
    },
    data_ready_l() {
      this.listeningAnimation.totalFrames = this.animListening.totalFrames;
    },
    handleAnimationL: function (anim) {
      let self = this;
      self.animListening = anim;
      self.animListening.addEventListener("data_ready", function () {
        self.listeningAnimation.totalFrames = self.animListening.totalFrames;
      });
    },
    gotoPrevious() {
      this.$emit("previous");
    },
    gotoNext() {
      let self = this;
      // console.log("Valor último", self.internValue);
      if (self.required) {
        if (self.isValid()) {
          if (self.value) {
            self.$emit("input", {
              id: self.formDetail.id,
              value: self.internValue,
            });
            // console.log("emitiendo valor normal", self.internValue);
            self.$emit("next");
          } else {
            self.canContinue = false;
          }
        }
      } else {
        // console.log("Continuando ");
        self.$emit("next");
      }
    },
    isValid() {
      let is_valid = false;
      if (this.$v.internValue.$invalid) {
        this.showErrors = true;
      } else {
        is_valid = true;
        this.showErrors = false;
      }
      return is_valid;
    },
  },
};
</script>

<style>
input {
  outline: none;
}
</style>
