<template>
  <div
    id="evaluation-forms"
  >
    <template v-for="(question, index) in questions">
      <template-form-date
        v-if="currentStep == ( index + 1 ) && question && question.form_type == 'date'"
        :formDetail="question"
        :required="question.is_mandatory"
        v-model="responsesValues[index]"
        @input="responsesValues[index]=$event"
        :key="index"
        @previous="currentStep--; changeResponse();"
        @next="currentStep++; changeResponse();"
      ></template-form-date>
      <TemplateFormDateRange
        v-if="currentStep == ( index + 1 ) && question && question.form_type == 'daterange'"
        :formDetail="question"
        :required="question.is_mandatory"
        v-model="responsesValues[index]"
        @input="responsesValues[index]=$event"
        :key="index"
        @previous="currentStep--; changeResponse();"
        @next="currentStep++; changeResponse();"
      ></TemplateFormDateRange>
      <template-form-input
        v-if="currentStep == ( index + 1 ) && question && question.form_type == 'input'"
        :formDetail="question"
        :required="question.is_mandatory"
        v-model="responsesValues[index]"
        @input="responsesValues[index]=$event"
        :key="index"
        @previous="currentStep--; changeResponse();"
        @next="currentStep++; changeResponse();"
      ></template-form-input>
      <template-form-option
        v-if="currentStep == ( index + 1 ) && question && question.form_type == 'option'"
        :formDetail="question"
        :required="question.is_mandatory"
        v-model="responsesValues[index]"
        @input="responsesValues[index]=$event"
        :key="index"
        @previous="currentStep--; changeResponse();"
        @next="currentStep++; changeResponse();"
      ></template-form-option>
      <template-form-select
        v-if="currentStep == ( index + 1 ) && question && question.form_type == 'select'"
        :formDetail="question"
        :required="question.is_mandatory"
        :api_resource="question.api_resource"
        :httpConnector="$http"
        v-model="responsesValues[index]"
        @input="responsesValues[index]=$event"
        :key="index"
        @previous="currentStep--; changeResponse();"
        @next="currentStep++; changeResponse();"
      ></template-form-select>
      <template-form-full-name
        v-if="currentStep == ( index + 1 ) && question && question.form_type == 'fullname'"
        :formDetail="question"
        :required="question.is_mandatory"
        v-model="responsesValues[index]"
        @input="responsesValues[index]=$event"
        :key="index"
        @previous="currentStep--; changeResponse();"
        @next="currentStep++; changeResponse();"
      >
      </template-form-full-name>
      <TemplateFormAddress
        v-if="currentStep == ( index + 1 ) && question && question.form_type == 'address'"
        :formDetail="question"
        :required="question.is_mandatory"
        v-model="responsesValues[index]"
        @input="responsesValues[index]=$event"
        :key="index"
        @previous="currentStep--; changeResponse();"
        @next="currentStep++; changeResponse();"
      >
      </TemplateFormAddress>
      <template-form-amount
        v-if="currentStep == ( index + 1 ) && question && question.form_type == 'amount'"
        :formDetail="question"
        :required="question.is_mandatory"
        v-model="responsesValues[index]"
        @input="responsesValues[index]=$event"
        :key="index"
        @previous="currentStep--; changeResponse();"
        @next="currentStep++; changeResponse();"
      >
      </template-form-amount>
      <!-- Templates especiales de CHUBB -->
      <template-form-chubb-valor-contenido
        v-if="currentStep == ( index + 1 ) && question && question.form_type == 'chubb_valor_contenido'"
        :formDetail="question"
        :required="question.is_mandatory"
        v-model="responsesValues[index]"
        :allResponses="responsesValues"
        @input="responsesValues[index]=$event"
        :key="index"
        @previous="currentStep--; changeResponse();"
        @next="currentStep++; changeResponse();"
      >
      </template-form-chubb-valor-contenido>
      <TemplateFormChubbValorInmueble
        v-if="currentStep == ( index + 1 ) && question && question.form_type == 'chubb_valor_inmueble'"
        :formDetail="question"
        :required="question.is_mandatory"
        v-model="responsesValues[index]"
        :allResponses="responsesValues"
        @input="responsesValues[index]=$event"
        :key="index"
        @previous="currentStep--; changeResponse();"
        @next="currentStep++; changeResponse();"
      >
      </TemplateFormChubbValorInmueble>
      <TemplateFormArray
        v-if="currentStep == ( index + 1 ) && question && question.form_type == 'array'"
        :formDetail="question"
        :required="question.is_mandatory"
        v-model="responsesValues[index]"
        :allResponses="responsesValues"
        @input="responsesValues[index]=$event"
        :key="index"
        @previous="currentStep--; changeResponse();"
        @next="currentStep++; changeResponse();"
      />
    </template>
  </div>
</template>

<script>
import TemplateFormDate from "./TemplateFormDate.vue";
import TemplateFormDateRange from "./TemplateFormDateRange.vue"
import TemplateFormInput from "./TemplateFormInput.vue";
import TemplateFormFullName from "./TemplateFormFullName.vue";
import TemplateFormAddress from "./TemplateFormAddress.vue";

import TemplateFormOption from "./TemplateFormOption.vue";
import TemplateFormSelect from "./TemplateFormSelect.vue";
import TemplateFormArray from "./TemplateFormArray.vue";
import TemplateFormAmount from "./TemplateFormAmount.vue";

import TemplateFormChubbValorInmueble from "./TemplateFormChubbValorInmueble.vue"
import TemplateFormChubbValorContenido from "./TemplateFormChubbValorContenido.vue"



export default {
  name: "Evaluation",
  data: function() {
    return {
      currentStep: 1,
      responsesValues: [],
      internalQuestions: [],
    };
  },
  props: ["questions", "responses"],
  components: {
    TemplateFormDate,
    TemplateFormDateRange,
    TemplateFormInput,
    TemplateFormFullName,
    TemplateFormAddress,
    TemplateFormOption,
    TemplateFormSelect,
    TemplateFormAmount,
    TemplateFormChubbValorContenido,
    TemplateFormChubbValorInmueble,
    TemplateFormArray,
  },
  computed: {
    lastSteep() {
      return this.questions.length + 1
    },
    userResponses() {
      return this.responsesValues.map(function(value, index) {
        return {
          id: index,
          value: value
        };
      })
    }
  },
  mounted() {
    this.currentStep = 1;
    this.responsesValues = this.responses;
  },
  destroyed() {},
  methods: {
    changeResponse() {
      if (this.currentStep == 0) {
        this.$router.go(-1)
      } else if (this.currentStep == this.lastSteep) {
        // console.log("Es el final")
        this.$emit("change-responses", {
          responses: this.userResponses,
          isEnd: true
        });
        return true;
      } else {
        this.$emit("change-responses", {
          responses: this.userResponses
        });
        return false;
      }
    }
  }
};
</script>

<style > 

.bg-blur::before {
  content: "";
  position: absolute;
  background-color: rgba(255, 255, 255, 0.783);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  filter: blur(10px);
  z-index: 0;
}
.bg-blur {
  position: relative;
}
.bg-blur > * {
  position: relative;
  z-index: 1;
}
</style>
